import * as React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const Card = ({ className, children }) => {
    return (
        <div className={`c-card${className && ` ${className}`}`}>
            {children}
        </div>
    )
}

Card.propTypes = {
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

Card.defaultProps = {
    className: '',
    children: '<p>Card content goes here</p>'
}

export default Card
